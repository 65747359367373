<template lang="pug">
div
  van-sticky(v-if='!this.$attrs.disabled')
    van-search(v-model='searchKey', placeholder='请输入报价单名称或者编号' @search="onSearch" show-action shape="round")
      template(#action='')
        div(@click='onSearch') 搜索
    van-dropdown-menu
      van-dropdown-item(v-model='offerType', :options='options')
      van-dropdown-item(v-model='sortType', :options='option2')
  offer-list(:quote_state='offerType' :search_key='searchKey' :refresh='refresh' :value='ofcCompanys')

</template>

<script>
import { mapGetters } from 'vuex'
import formmixin from '../../components/form'
export default {
  props: ['ofcCompanys'],
  mixins: [formmixin],
  data() {
    return {
      refresh: false,
      searchKey: '',
      offerType: 'all',
      options: [{ text: '全部', value: 'all' }],
      sortType: '',
      // option1: [
      //   { text: '全部', value: 'all' },
      //   { text: '未提交', value: 'unoffer' },
      //   { text: '报价中', value: 'offering' },
      //   { text: '已下单', value: 'had_order' },
      //   { text: '已取消', value: 'canceled' },
      // ],
      option2: [
        { text: '默认排序', value: '' },
        // { text: '时间', value: '-update_time' },
      ],
    }
  },
  computed: {
    ...mapGetters(['getFlow'])
  },
  created() {
    this.init()
  },
  async mounted() {
    this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      onClickLeft: () => {
        this.$router.go(-1)
      }
    })
  },
  methods: {
   // 初始化数据
    async init () {
      let res = await this.getFlow('quote_state')
      if (res.status) {
        let keys = Object.keys(res.status)
        let status = keys.map((item) => {
          return { 
            value: item,
            text: res.status[item].alias
          }
        })
        this.options = this.options.concat(status)
      }
    },
    onClick(key, value){
      this.offerType = key
    },
    onSearch() {
      // this.$refs.list.refresh()
      this.refresh = !this.refresh
    },
  },
}
</script>

<style lang="less" scoped>
.offer-card {
  background-color: white;
}
</style>