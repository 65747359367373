<template lang="pug">
div
  van-field(v-for="(item, index) of field"
    :key= 'index'
    :label="item.label"
    :disabled="!editCustomer"
    :required="item.required"
    v-model="data[item.key]"
    clearable
    )
  van-tabs(v-model='active' sticky)
    van-tab(
      v-for="(tab,index) of tabs"
      :key="index"
      :title='tab.name'
      :to="{name: 'customerinfo',query: {id: data.id, tab:index}}",
      replace 
      )
      component(:is="tab.component" v-if="active == index" :value='data.id' :isComponent='tab.isComponent' :disabled='true' :ofcCompanys='data.id')
</template>

<script>
import { Sticky } from 'vant'
import AddressList from './_AddressList'
import ProjectList from './project'
import ContactList from './_ContactList'
import Offer from './offer'
import Order from './order'

import formmixin from '../../components/form'

export default {
  mixins: [formmixin],
  components: {
    [Sticky.name]: Sticky,
    AddressList,
    ProjectList,
    ContactList,
    Offer,
    Order,
  },
  data() {
    return {
      data: {
        id: 0,
        spname: '',
        fullname: '',
        tel: '',
        remark: ''
      },
      field: [
        { label: '客户简称：', key: 'spname', required: true},
        { label: '客户全称：', key: 'fullname', required: true},
        { label: '联系电话：', key: 'tel', required: false},
        { label: '备注：', key: 'remark', required: false},
      ],
      active: 0,
      tabs: [
        {
          name: '项目',
          component: ProjectList,
          isComponent: true,
        },
        {
          name: '报价单',
          component: 'offer-list',
        },
        {
          name: '订单',
          component: Order,
          isComponent: true,
        },
        {
          name: '联系人',
          component: ContactList
        },
        {
          name: '地址管理',
          component: AddressList
        }
      ],
      orders: [],
      loading: false,
      finished: true,
      refreshing: false,
      editCustomer: false
    }
  },
  computed: {
    rightText () {
      return this.editCustomer ? '提交' : '修改'
    },
  },
  methods: {
    async onConfirm() {
      if(!this.isEmpty(this.data.spname) && !this.isEmpty(this.data.fullname)){
        try {
          let res = await this.$api.PutCustomerInfo(this.data)
          this.$toast('修改成功')
        } catch (error) {
          this.$toast('修改失败' + error)
        }
      }
      else{
        this.$toast('公司简称和全称不能为空')
      }
    },
    async onLoad() {
      try {
        // this.data.id = 2509
        // console.log(this.data.id)
        let res = await this.$api.GetCustomerInfo(this.data.id )
        this.data = res.data
        // console.log('获取客户详情',res)
      } catch (ex) {
          // console.log(`获取客户详情失败:${ex}`)
      }
    },
    isEmpty(str){ 
      if(str==null || typeof str=="undefined" || str.trim()=="") { 
        return true
      }else{ 
        return false
      } 
    },
    refreshNav() {
      this.$emit('on-nav', {
        leftText: '返回',
        leftArrow: true,
        rightText: this.rightText,
        onClickRight: () => {
          if (this.editCustomer) {
            this.onConfirm()
          }
          this.editCustomer = !this.editCustomer
          this.refreshNav()
        },
        onClickLeft: () => {
          this.$router.go(-1)
        }
      })
    }
  },
  mounted() {
    this.data.id = (this.$route.query).id || 0
    // this.data = this.$route.params.data
    this.active = (this.$route.query).tab || 0
    this.onLoad()
    this.refreshNav()
  }
}
</script>